@import './colors.scss';

.ui .header {
  color: $color-white;
  padding-bottom: 0.7rem;
}

.ui.container {
  font-size: 14pt;
  max-width: 1024px !important;
}

.ui.menu.fixed {
  height: 75px;
}

.ui.menu {
  background-color: #9ec2e2;
  border: none;

  .left.header.item {
    font-size: 1.5rem;
    border-left: none !important;
    color: $color-white;
  }

  .item,
  .header.item {
    color: $color-dark;
    font-weight: normal !important;
  }

  .item:before {
    width: 0;
  }

  .item:hover,
  .header.item:hover {
    color: $color-link-orange !important;
  }

  .active.item {
    border-bottom: solid 6px #7457b3;
    background: none;
    padding-bottom: 11px;
  }

  .active.item::after {
    display: none;
  }

  .ui.dropdown {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    color: $color-dark;

    .menu {
      border: none;
    }

    .active.selected.item {
      border: unset;
      background: rgba(80, 32, 170, 0.2) !important;
    }
  }
}

.ui.vertical.menu .item {
  color: $color-white;
}

.ui.vertical.menu .active.item {
  border-left: solid 5px $color-link-orange;
  border-bottom: unset;
}

.ui.button {
  margin: 30px auto 20px;
  padding: 24px 40px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  color: $color-white;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  -webkit-box-shadow: 0 7px 3.56px 0.44px #08080b;
  -moz-box-shadow: 0 7px 3.56px 0.44px #08080b;
  box-shadow: 0 7px 3.56px 0.44px #08080b;
}

.ui.search,
.ui.selection.dropdown {
  background-color: $color-input-background;
  color: #ffae00;
  font-size: 0.85rem;
}

.ui.dropdown .menu > .item {
  font-size: 0.85rem;
}

.ui.search .prompt {
  border-radius: 3px;
}

.ui.icon.input > i.icon {
  color: $color-link-orange;
}

.ui.input > input,
.ui.input > input:focus {
  background-color: $color-input-background;
  color: #ffae00;
}

.ui.progress {
  background: #5120ab;
}

.ui.progress .bar {
  background: #f1b500;
}

.ui.progress .bar > .progress {
  position: static;
  text-align: right;
  line-height: 30px;
  color: $color-dark;
}

a:hover {
  color: $color-link-orange !important;
}

.ui.button.link:hover {
  color: $color-link-orange !important;
}

.ui.modal > .close.icon {
  color: $color-dark;
}

.wallet-card {
  .button.link:hover {
    color: $color-link-orange !important;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.main-menu-mobile.ui.menu {
  .header.item.logo {
    font-size: 1rem;
    padding-right: 5px;
  }

  .ui.dropdown {
    padding-right: 0;
    font-size: 1rem;
  }
}
