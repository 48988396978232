$color-link-yellow: #ffff00;
$color-link-blue: #ffff00;
$color-link-orange: #c45b38;
$color-blue-text: #5073f3;

$color-white: #fff;
$color-blue: #1d43bf;
$color-dark: #000;

$color-border: #1f4f6a;
$color-body-background: #0a1936;//#aed5f4;//#0a1936;
$color-page-background: #aed5f4;
$color-input-background: #053752;
